
    import axios from 'axios';
    import { Component, Vue } from "vue-property-decorator";
    import { GraphUtils } from '../../ts/utils/GraphUtils';

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class EconomyProperty extends Vue {
        // Methods -----------------------------------------------------------------------------------------------------
        private data() {
            return {
                keysIconPathAndName:
                    require("@/assets/images/icon_keys.png"),
            };
        }
    }
